import React, {useState, useEffect} from "react";
import FileBase from 'react-file-base64';
import { useSelector } from "react-redux";
import useStyles from './styles';
import { TextField, Button, Typography, Paper } from '@material-ui/core';
import { useDispatch } from "react-redux";

import { createPost, updatePost } from "../../actions/posts";


const Form = ({ currentId, setCurrentId }) => {
    const classes = useStyles();
    const post = useSelector((state) => (currentId ? state.posts.find((message) => message._id === currentId) : null));


    // React-Redux for populating form value
    useEffect(() => {
        if (post) {
            setPostData(post);
        } 
      
    }, [post]);

    const [postData, setPostData] = useState({
        title : '', 
        message : '',
        tags: '', 
        selectedFile : ''
    });

    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('profile'));

    const handleSubmit = (e) => {
        
        if (currentId === 0) {
            dispatch(createPost({...postData, name : user?.result?.name}));
            clearForm();
        } else {
            e.preventDefault();
            console.log('edit');
        
            dispatch(updatePost(currentId, {...postData, name : user?.result?.name}));
            clearForm();
        }
   
    };

    const clearForm = () => {
        setCurrentId(null);
        setPostData(  {
        title : '', 
        message : '',
        tags: '', 
        selectedFile : ''});
    }

    if (!user?.result?.name) {
        return (
            <Paper className = {classes.paper}>
                <Typography variant = "h6" align = "center">Please sign into create a FitCheck.</Typography>
            </Paper>
        )
    }

    return (
        <Paper className = {classes.paper}>
            <form autoComplete = "off" noValidate className = {`${classes.root} ${classes.form}`} onSubmit={handleSubmit}>
                <Typography variant = "h6">{
                currentId ? `Editing` : 'Create'} a FitCheck</Typography>

                <TextField name = "title" variant = "outlined" label = "Title" fullWidth value = {postData.title} onChange = {(e) => setPostData({ ...postData, title: e.target.value })}></TextField>
                <TextField name = "message" variant = "outlined" label = "Message" fullWidth value = {postData.message} onChange = {(e) => setPostData({ ...postData, message: e.target.value })}></TextField>
                <TextField name = "tags" variant = "outlined" label = "Tags" fullWidth value = {postData.tags} onChange = {(e) => setPostData({ ...postData, tags: e.target.value.split(',') })}></TextField>
                
                <div className={classes.fileInput}>
                    <FileBase type="file" multiple={false} onDone={({ base64 }) => setPostData({ ...postData, selectedFile: base64 })} />
                </div>
                
                <Button className={classes.buttonSubmit} variant="contained" color="primary" size="large" type="submit" fullWidth>Submit</Button>
                <Button variant="contained" color="secondary" size="small" onClick = {clearForm} fullWidth>Clear</Button>


            </form>
        </Paper>
    );
}

export default Form;